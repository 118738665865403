<template>
  <div class="ability-system">
    <div class="manage-wrapper">
      <div class="container">
        <div class="title-box">
          <div class="title">{{title}}综合素质能力标准</div>
          <div class="line"></div>
        </div>
        <div class="cont-box">
          <el-select v-model="selected" placeholder="请选择" v-if="isSelect()" size="mini">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img :src="imgList[role][selected]" alt="" class="picture"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    mapState
  } from "vuex";
  export default {
    data() {
      return {
        options: [{
          value: 0,
          label: '教师综合素质评价标准'
        }, {
          value: 1,
          label: '学生综合素质评价标准'
        }],
        selected: 0,
        imgList: {
          'student': [
            require("../../assets/images/ability/student-light.png"),
          ],
          'teacher': [
            require("../../assets/images/ability/teacher-light.png"),
          ],
          'leader': [
            require("../../assets/images/ability/teacher-unlight.png"),
            require("../../assets/images/ability/student-unlight.png")
          ],
          'expert': [
            require("../../assets/images/ability/teacher-unlight.png"),
            require("../../assets/images/ability/student-unlight.png")
          ],
          'assistant': [
            require("../../assets/images/ability/teacher-unlight.png"),
            require("../../assets/images/ability/student-unlight.png")
          ],
          'school': [
            require("../../assets/images/ability/teacher-unlight.png"),
            require("../../assets/images/ability/student-unlight.png")
          ],
          'manager': [
            require("../../assets/images/ability/teacher-unlight.png"),
            require("../../assets/images/ability/student-unlight.png")
          ],
          'parent': [
            require("../../assets/images/ability/teacher-unlight.png"),
            require("../../assets/images/ability/student-unlight.png")
          ]
        },
        imgURL: '',
        isSelect: () => {
          if (this.role !== 'student' && this.role !== 'teacher') {
            return true
          } else {
            return false
          }
        },
      }
    },
    computed: {
      title() {
        if (this.role === 'student') return '学生';
        if (this.role === 'teacher') return '教师';
        if (this.role !== 'student' || this.role !== 'teacher') {
          return this.selected ? '学生' : '教师'
        }
      },
      ...mapState({
        role: state => state.user.role,
      })
    }
  };
</script>


<style lang="less" scoped>
  .ability-system {
    .container {
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px #F5F8FA;
      border-radius: 8px;
      margin-top: 36px;

      .title-box {
        border-bottom: 1px solid #f2f2f2;
        width: 100%;
        text-align: center;
        position: relative;

        .title {
          font-size: 16px;
          font-weight: bold;
          color: #508EF9;
          line-height: 50px;
        }

        .line {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 205px;
          height: 3px;
          background-color: #508EF9;
          border-radius: 4px;
        }
      }

      .cont-box {
        overflow: hidden;

        /deep/ .el-select {
          margin: 15px 35px;

          .el-input__inner {
            border-radius: 25px;
          }
        }

        .picture {
          display: block;
          max-width: 100%;
          margin: 30px auto 0;
        }

      }
    }
  }
</style>